//Polyfill
import "core-js";

//Can't resolve 'regenerator-runtime/runtime'が表示されたとき(async awaitを使う場合など)
//import  regeneratorRuntime  from  "regenerator-runtime" ;
import jQuery from "jquery";

const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)

$(document).ready(function () {
  // .header-btnがクリックされたときの処理
  $(".header-btn").click(function () {
    // .header-btnと.header-spのopenクラスをトグル（追加/削除）
    $(".header-btn, .header-sp").toggleClass("open");
  });

  // .header-sp内のaタグがクリックされたときの処理
  $(".header-sp a").click(function () {
    // .header-btnと.header-spからopenクラスを削除
    $(".header-btn, .header-sp").removeClass("open");
  });
});

document.addEventListener("DOMContentLoaded", () => {
  const tabs = document.querySelectorAll(".tab");
  const contents = document.querySelectorAll(".tab-content");

  tabs.forEach((tab) => {
    tab.addEventListener("click", () => {
      const target = tab.getAttribute("data-tab");

      tabs.forEach((t) => t.classList.remove("active"));
      tab.classList.add("active");

      contents.forEach((content) => {
        if (content.getAttribute("id") === target) {
          content.classList.add("active");
        } else {
          content.classList.remove("active");
        }
      });
    });
  });
});
